import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/styles/styles.css"
import marked from "marked"
import Dform from "../components/dynamicForm"
import Pagetab from "../components/page-tab"

// import portable from "../../static/assets/img/portable-img.png"
// import vehicleMounted from "../../static/assets/img/vehicle-mounted.jpg"
// import lateral from "../../static/assets/img/lateral.jpg"
// import manhole from "../../static/assets/img/manhole.jpg"
// import rehab from "../../static/assets/img/rehab.jpg"

const PageTemplate = ({ data, location }) => {
  console.log("test", location)
  const sewer = data.sanityPageCategory
  console.log("sewers", sewer)
  return (
    <Layout>
      <SEO title={data.post.metaTitle} description={data.post.metaDesc} />
      <div
        className="page-headline"
        style={{
          // backgroundImage: `url(${
          //   data.post.mainImage !== null
          //     ? data.post.mainImage.asset.fluid.src
          //     : "https://source.unsplash.com/user/neotronimz/1920x1080"
          // })`,
          background: "#0A3E5A",
        }}
      >
        <div className="container">
          <div className="section-heading text-center">
            <h1 className="font-weight-bold text-uppercase flair">
              <strong>{data.post.title}</strong>
            </h1>
            <p className="sub-heading text-center">
              {location.pathname === "/inspection-equipment" ||
              "/inspection-equipment/"
                ? "We provide top-quality inspection equipment sales and rental services. Expect reliable and versatile products and affordable packages."
                : null}
            </p>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              {sewer ? <Pagetab pageCat={sewer} /> : null}
            </div>

            <div className="col-md-9">
              <div
                className="dynamic-wrap"
                dangerouslySetInnerHTML={{
                  __html: marked(data.post.body),
                }}
              />
              <div className="row">
                <div className="col-md-8 m-auto">
                  {data.post.withForm === true ? <Dform /> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PageTemplate

export const PageQuery = graphql`
  query PageTemplateQuery($id: String!, $title: String!) {
    post: sanityPage(id: { eq: $id }) {
      id
      mainImage {
        asset {
          fluid {
            src
          }
        }
      }
      body
      title
      slug {
        current
      }
      withForm
      metaTitle
      metaDesc
    }
    sanityPageCategory(pages: { elemMatch: { title: { eq: $title } } }) {
      id
      title
      slug {
        current
      }

      pages {
        id
        title
        slug {
          current
        }
      }
    }
  }
`
