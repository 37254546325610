import React from "react"
import WebriQForm from "../components/form/form"

const DynamicForm = () => {
  return (
    <div className="page-form">
      <WebriQForm
        form_name="Page Form"
        form_id="d645e6f5-2693-4f45-929b-259696f274f1"
        data-thankyou-url="/thank-you"
      >
        <div className="row shadow p-3">
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="name"
                placeholder="Name"
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                name="email"
                placeholder="Email"
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="subject"
                placeholder="Subject"
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <textarea
                className="form-control"
                name="message"
                placeholder="Message"
                rows="4"
              ></textarea>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <div className="webriq-recaptcha" />
            </div>
          </div>
          <div className="form-group col-12">
            <button
              className="btn-global hvr-bounce-to-right hvr-icon-buzz w-100 text-center"
              type="submit"
            >
              <i className="fa fa-envelope mr-2 hvr-icon hvr-icon"></i>
              Send Message
            </button>
          </div>
        </div>
      </WebriQForm>
    </div>
  )
}

export default DynamicForm
