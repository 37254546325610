import React from "react"
import { Link } from "gatsby"
// import { Nav, Dropdown, NavItem } from "react-bootstrap"

import "./sidebar/menu.css"

const Pagetab = props => {
  const category = props.pageCat
  return (
    <div className="navigation text-left category">
      <ul className="list-unstyled d-grid mt-4">
        <li>
          <Link to="/">home</Link>
        </li>
        <li>
          {category.slug && category.slug.current ? (
            <Link to={`/${category.slug.current}`}>{category.title}</Link>
          ) : (
            <li className="li-exclude">{category.title}</li>
          )}

          <ul className="list-unstyled">
            {category.pages.length !== 0
              ? category.pages.map(page => (
                  <li>
                    <Link
                      to={`/${page.slug.current}`}
                    >{`- ${page.title}`}</Link>
                  </li>
                ))
              : null}
          </ul>
        </li>
      </ul>
    </div>
  )
}

export default Pagetab
